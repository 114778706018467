import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { getExpos } from '../../api/api';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api`;

interface Props {}

const Expositions: React.FC<Props> = () => {

	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [expositions, setExpositions] = useState<any[]>([]);

	// Request expo's data

	const requestExpos = async () => {
		
		setIsLoading(true);
		
		try {
			const response = await getExpos();

			setIsLoading(false);

			if (response.error) {
				setError(response.error);
				return;
			}

			if (response.length > 0)
				setExpositions(response);
			else
				setExpositions([]);

		} catch (err: any) {
			console.log('CATCH ERR requestExpos', err, err.message);
			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while getting expos. Please try again later.');
		}
	}

	useEffect(() => {
		requestExpos();
	},[]);

  	return (
  		<Container>
      		<Row>
      			<Col md={{ span: 6, offset: 3 }}>
      				<Row>
		      			{
		      				expositions.map((expo, expoIndex) => <Col key={expoIndex} md={6} style={{paddingTop: 12, paddingBottom: 12}}>
		      						<Link style={{textDecoration: 'none', color: 'black'}} to={`/exposition/${expo.id}`}>
			      						<Row>
					      					<img src={`${BASE_URL}/photos/o/${expo.filename}`} alt="Alt" style={{width: '100%', height: '28vh'}} />
					      				</Row>
			      						<Row>
					      					<span className="mt-1">{expo.name}</span>
					      				</Row>
			      						<Row>
					      					<span className="mt-1">{expo.date}</span>
					      				</Row>
				      				</Link>
				      			</Col>
		      				)
		      			}
	      			</Row>
      			</Col>
      		</Row>
    	</Container>
  	);
};

export default Expositions;