import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { sendContactMessage } from '../../api/api';
import { useLang } from '../Layout/Layout';


interface Props {}

const Contact: React.FC<Props> = () => {

	const i18n:any = {
		'fr': {
			firstname: 'Prenom',
			lastname: 'Nom',
			email: 'Email',
			source: "Comment m'avez vous connu ?",
			message: 'Message',
			send: 'Envoyer',
			or: 'Ou'
		},
		'en': {
			firstname: 'First name',
			lastname: 'Last name',
			email: 'Email',
			source: "How have you know me ?",
			message: 'Message',
			send: 'Send',
			or: 'Or'
		}
	};

	const { lang } = useLang();
	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [lastname, setLastname] = useState('');
	const [firstname, setFirstname] = useState('');
	const [email, setEmail] = useState('');
	const [source, setSource] = useState('');
	const [message, setMessage] = useState('');

	const handleLastnameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setLastname(e.target.value);
	};

	const handleFirstnameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setFirstname(e.target.value);
	};

	const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	};

	const handleSourceChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSource(e.target.value);
	};

	const handleMessageChange = (e: ChangeEvent<HTMLInputElement>) => {
		setMessage(e.target.value);
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(true);
		
		if (!firstname || !lastname || !email || !source || !message)
			return alert("Please enter fill all fields !");

		try {
			const sendMessageResponse = await sendContactMessage({ firstname, lastname, email, source, message_body: message });

			console.log('RESPONSE sendMessageResponse', sendMessageResponse);

			setIsLoading(false);
			
			if (sendMessageResponse.error) {
				setError(sendMessageResponse.error);
				return;
			}

			if (sendMessageResponse.message)
				setSuccess(sendMessageResponse.message);


			// reset states
			setError('');
			setFirstname('');
			setLastname('');
			setEmail('');
			setSource('');
			setMessage('');
			
			alert("Message envoyé");

		} catch (err: any) {
			console.log('CATCH ERR', err, err.message);

			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while file upload. Please try again later.');
		}
	};



  return (
  	<Container>
	  	<Row>
	  		<h4 className="mb-4">Contact</h4>
	  	</Row>
  		<Row>
	  		<Col md={{span: 4, offset: 2}}>
	  			<Row>
			  		<Form onSubmit={handleSubmit} className="mb-2">
				  		<Form.Group className="mb-3" controlId="formLastname">
					  		<Form.Control
					  		type="text"
					  		placeholder={i18n[lang].lastname}
					  		value={lastname}
					  		onChange={handleLastnameChange}
					  		style={{ backgroundColor: 'white', color: 'black', borderRadius: 0, borderBottom: '1px solid black' }}
					  		required
					  		/>
				  		</Form.Group>
				  		<Form.Group className="mb-3" controlId="formFirstname">
					  		<Form.Control
					  		type="text"
					  		placeholder={i18n[lang].firstname}
					  		value={firstname}
					  		onChange={handleFirstnameChange}
					  		style={{ backgroundColor: 'white', color: 'black', borderRadius: 0, borderBottom: '1px solid black' }}
					  		required
					  		/>
				  		</Form.Group>
				  		<Form.Group className="mb-3" controlId="formEmail">
					  		<Form.Control
					  		type="email"
					  		placeholder={i18n[lang].email}
					  		value={email}
					  		onChange={handleEmailChange}
					  		style={{ backgroundColor: 'white', color: 'black', borderRadius: 0, borderBottom: '1px solid black' }}
					  		required
					  		/>
				  		</Form.Group>
				  		<Form.Group className="mb-3" controlId="formSource">
					  		<Form.Control
					  		type="text"
					  		placeholder={i18n[lang].source}
					  		value={source}
					  		onChange={handleSourceChange}
					  		style={{ backgroundColor: 'white', color: 'black', borderRadius: 0, borderBottom: '1px solid black' }}
					  		required
					  		/>
				  		</Form.Group>

				  		<Form.Group className="mb-3" controlId="formMessage">
					  		<Form.Control
					  		as="textarea"
					  		placeholder={i18n[lang].message}
					  		rows={5}
					  		value={message}
					  		onChange={handleMessageChange}
					  		style={{ backgroundColor: 'white', color: 'black', borderRadius: 0, borderBottom: '1px solid black' }}
					  		required
					  		/>
				  		</Form.Group>
				  		<Button variant="dark" type="submit" disabled={isLoading} style={{ width: '100%', border: 0, borderRadius: 0 }}>
				  			{i18n[lang].send}
				  		</Button>
			  		</Form>
			  	</Row>
	  		</Col>
	  		<Col md={{span: 1}}>
	  			<Row>
			  		<span>{i18n[lang].or}</span>
			  	</Row>
	  		</Col>
	  		<Col md={{span: 3}}>
	  			<Row>
			  		<span>Email : basma.photographe@gmail.com</span>
			  	</Row>
	  			<Row>
			  		<span>Tel : +33 7 66 35 87 40</span>
			  	</Row>
	  		</Col>
  		</Row>
    </Container>
  );
};

export default Contact;