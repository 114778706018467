import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { getGalleryPhotos, getGallery } from '../../api/api';
import leftArrowIcon from '../../assets/icons/left-arrow.png';
import rightArrowIcon from '../../assets/icons/right-arrow.png';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api`;

interface Props {}

const GalerieSerie: React.FC<Props> = () => {

	let { galleryId } = useParams();
	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [gallery, setGallery] = useState<any>(null);
	const [gallerySeriePhotos, setGallerySeriePhotos] = useState<any[]>([]);
	const [selectedPicture, setSelectedPicture] = useState<{ id:number; title:string; filename:string; date:string; exhibition_id:number } | null>(gallerySeriePhotos.length > 0 ? gallerySeriePhotos[0] : null);


	const requestGallery = async (galleryId:any) => {

		setIsLoading(true);

		try {
			const response = await getGallery(galleryId);

			setIsLoading(false);

			if (response.error) {
				setError(response.error);
				return;
			}

			if (response.length > 0) {
				setGallery(response[0]);
			}

		} catch (err: any) {
			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while getting gallery. Please try again later.');
		}
	}

	const requestgallerySeriePhotos = async (galleryId:any) => {

		setIsLoading(true);

		try {
			const response = await getGalleryPhotos(galleryId);

			setIsLoading(false);

			if (response.error) {
				setError(response.error);
				return;
			}

			if (response.length > 0) {
				setGallerySeriePhotos(response);
				setSelectedPicture(response[0]);
			} else {
				setGallerySeriePhotos([]);
			}

		} catch (err: any) {
			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while getting gallery photos. Please try again later.');
		}
	}

	const nextPhoto = (picture:any) => {
		const index = gallerySeriePhotos.map((e) => {return e.id;}).indexOf(picture.id);
		setSelectedPicture(gallerySeriePhotos[index + 1]);
	}

	const prevPhoto = (picture:any) => {
		const index = gallerySeriePhotos.map((e) => {return e.id;}).indexOf(picture.id);
		setSelectedPicture(gallerySeriePhotos[index - 1]);
	}

	useEffect(() => {
		if (galleryId) {
			requestGallery(galleryId);
			requestgallerySeriePhotos(galleryId);
		}
	},[galleryId]);

  	return (
  		<Container>
      		<Row>
      			<h2 className="p-1">{ gallery && gallery.name }</h2>
      		</Row>
      		<Row className="justify-content-md-center">
      			{
      				gallerySeriePhotos.map((photo, photoIndex) => <Col key={photoIndex} md={1} style={{paddingTop: 12, paddingBottom: 12}}>
		      				<img src={`${BASE_URL}/photos/m/${photo.filename}`} alt="Alt" style={{width: '100%', cursor: 'pointer'}} onClick={() => setSelectedPicture(photo)} />
		      			</Col>
      				)
      			}
      		</Row>
      		<Row>
	  			<Col md={1} style={{display : 'flex', alignItems: 'center'}}>
	  				{ selectedPicture && selectedPicture.id > gallerySeriePhotos[0].id ? <img src={leftArrowIcon} alt="Left arrow" style={{width: '100%'}} onClick={() => prevPhoto(selectedPicture)}/> : null }
	  			</Col>
      			<Col md={{span: 10}}>
	      			{ selectedPicture && selectedPicture.filename ? <img src={`${BASE_URL}/photos/o/${selectedPicture.filename}`} alt="Alt" style={{maxWidth: '100%', maxHeight: '70vh'}} /> : null }
	  			</Col>
	  			<Col md={1} style={{display : 'flex', alignItems: 'center'}}>
	  				{ selectedPicture && selectedPicture.id < gallerySeriePhotos[gallerySeriePhotos.length - 1].id ? <img src={rightArrowIcon} alt="Right arrow" style={{width: '100%'}} onClick={() => nextPhoto(selectedPicture)}/> : null }
	  			</Col>
      		</Row>
    	</Container>
  	);
};

export default GalerieSerie;