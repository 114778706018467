import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLang } from '../Layout/Layout';

import mainBleues from '../../assets/images/home_pic.jpg';

interface Props {}

const Home: React.FC<Props> = () => {

  const i18n:any = {
    'fr': {
      quotation: 'La photographie aide les gens à voir'
    },
    'en': {
      quotation: 'Photography helps people to see'
    }
  };
  const { lang } = useLang();

  return (
  	<Container>
  		<Row>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
      		<h2 className="p-3" style={{fontStyle: "italic"}}>« {i18n[lang].quotation} »</h2>
          <span> Bérénice Abbott</span>
        </div>
  		</Row>
  		<Row>
  			<Col md={{span: 10, offset: 1}}>
      			<img src={mainBleues} alt="Alt" style={{width: '100%'}} />
  			</Col>
  		</Row>
    </Container>
  );
};

export default Home;