import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import portrait from '../../assets/images/portrait.jpg';
import { useLang } from '../Layout/Layout';

interface Props {}

const About: React.FC<Props> = () => {
  
  const { lang } = useLang();

  return (
  	<Container>
  		<Row>
  			<Col md={{span: 4}}>
      			<img src={portrait} alt="Alt" style={{width: '100%'}} />
  			</Col>
  			<Col md={{span: 6}} style={{textAlign: 'justify'}}>
          {
            lang === 'fr' ?
              <>
          			<p>Je me présente, je m’appelle ZIROURI Basma.</p>
          			<p>Passionnée de photographie depuis petite.</p>
                <p>La photographie s’est imposée progressivement dans ma vie jusqu’à en devenir mon métier.</p>
                <p>Depuis décembre 2019, je me suis lancée en tant que photographe indépendante après avoir été formée aux Gobelins à Paris. Mais ce n’est qu’en janvier 2021 que je laisse mon métier d’animatrice et me consacre entièrement à la photographie.</p>
                <p>J’ai tout d’abord commencé par la prise en photos de bébé (new born). Puis au fil de mes rencontres, j’ai voulu que mon outil photographique puisse servir plus loin qu’un simple souvenir. Je voulais permettre aux gens de se voir autrement, leur apporter un espace où la bienveillance serait la priorité.</p>               
                <p>Mon travail se concentre principalement sur les femmes de tout horizon. Je m’aide du médium de la photographie pour mettre en valeur des femmes qui parfois se sous-estiment. J’aime montrer la diversité.</p>
                <p>Elle m’a fait renaitre, c’est un peu fort comme déclaration mais depuis qu’elle est rentrée dans ma vie, mon regard sur le monde a changé. Au départ comme moyen d’expression puis comme moyen qui me permet de vivre et de voir autrement. Avec de la patience, beaucoup de travail et des formations, c’est devenu mon métier, un métier qui me passionne tant par les challenges que par les rencontres qu’il me permet de faire.</p>
                <p>Je me surprends à apprécier la beauté de ce qui m’entoure : un arbre en floraison, un lever ou coucher de soleil, des nuages aux formes particulières, des reflets dans une flaque d’eau, un rire, une larme, …</p>
                <p>Saisir un simple regard, un sourire ou même un mouvement peut rendre une simple photo en splendeur.</p>
                <p>Je réalise la plupart de mes prises de vues à la lumière naturelle afin qu'elles puissent faire ressortir toutes les couleurs ! Les images ainsi créées en sont d’autant plus spontanées et vivantes.﻿</p>
      			  </>              
            : lang === 'en' ?
              <>
                <p>Let me introduce myself, my name is ZIROURI Basma.</p>
                <p>Passionate about photography since I was a child.</p>
                <p>Photography gradually became a part of my life until it became my profession.</p>
                <p>Since December 2019, I have been working as a freelance photographer after being trained at the Gobelins in Paris. In January 2021 I left my job as an animator and devoted myself entirely to photography.</p>
                <p>First of all, I started by taking pictures of babies (new born). Then, over the course of my encounters, I wanted my photographic tool to be useful beyond a simple memory. I wanted to allow people to see themselves differently, to provide them with a space where kindness would be the priority.</p>
                <p>My work focuses primarily on women from all walks of life. I use the medium of photography to highlight women who sometimes underestimate themselves. I like to show diversity.</p>
                <p>She brought me back to life, that's a bit of a strong statement, but since she came into my life, my view of the world has changed. Initially as a means of expression, then as a means that allows me to live and see differently. With patience, a lot of work and training, it has become my job, a job that fascinates me as much by the challenges as by the encounters it allows me to make.</p>
                <p>I find myself appreciating the beauty of what surrounds me: a tree in bloom, a sunrise or sunset, clouds with particular shapes, reflections in a puddle, a laugh, a tear,... Capturing a simple look, a smile, or even a movement can make a simple photo look great.</p>
                <p>I shoot most of my shots in natural light so that they can bring out all the colors! The images created in this way are all the more spontaneous and vivid.</p>
              </>
            : null
          }
        </Col>
  		</Row>
  		<Row>
  		</Row>
    </Container>
  );
};

export default About;