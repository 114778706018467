import React from 'react';
import { Outlet } from "react-router-dom";
import { Container } from 'react-bootstrap';

import './Dashboard.css';

interface Props {}

const Dashboard: React.FC<Props> = () => {
  return (
  	<div>
      	<nav className="dashboard-nav">
			<h3 style={{color: 'white'}}>Backoffice</h3>
		</nav>
		<Container fluid className="p-5 mt-5">
      		<Outlet />
      	</Container>
    </div>
  );
};

export default Dashboard;