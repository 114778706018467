import axios from 'axios';
import { User } from '../types'; // import the types for the user data
// import { REACT_APP_BASE_URL } from "@env";
import { handleSuccess, handleError } from './manager';
import setAuthToken from '../middleware/setAuthToken';

// import { navigate } from '../navigation/RootNavigation';

export const baseAPI = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
});

baseAPI.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    const token = await localStorage.getItem('@access_token');
    // console.log('BEFORE setAuthToken', token);
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      baseAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete config.headers['Authorization'];
      delete baseAPI.defaults.headers.common['Authorization'];
      // navigate('Login', {});
    }
    return config;
  },
  (error) => {
    // Do something with request error
    // console.log('REQUEST ERROR users');
    return Promise.reject(error);
  }
);

baseAPI.interceptors.response.use(
  (response) => {
    // Do something with response data
    // console.log('RESPONSE AFTER users');
    return response;
  },
  (error) => {
    // Do something with response error
    // console.log('INTERCEPTOR error', error);
    const { status } = error.response;
    if (status === 401) {
        // navigate('Login', {});
    }
    return Promise.reject(error);
  }
);

// User endpoints

export const createUser = async (user: User) => {
    try {
        const response = await baseAPI.post('', user);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        handleError(error?.response?.status, error);
    }
};

export const authUser = async (user: User) => {
    try {
        const response = await baseAPI.post('/login', user);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        console.log('authUser ERROR', error);
        handleError(error?.response?.status, error);
    }
};

// Serie endpoints

export const addSerie = async (serie: any) => {
    try {
        const response = await baseAPI.post(`/photo-series`, serie);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        console.log('addSerie ERROR', error);
        handleError(error?.response?.status, error);
    }
};

export const getSeries = async () => {
    try {
        const response = await baseAPI.get(`/photo-series`);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        handleError(error?.response?.status, error);
    }
};

export const getSerie = async (serieId: number) => {
    try {
        const response = await baseAPI.get(`/photo-series/${serieId}`);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        handleError(error?.response?.status, error);
    }
};

export const uploadPhoto = async (formData: any) => {
    try {
        const response = await baseAPI.post('/upload-photo', formData, { headers: { "Content-type": "multipart/form-data" } });
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        console.log('uploadPhoto ERROR', error);
        handleError(error?.response?.status, error);
    }
};

export const addSeriePhoto = async (serieId: number, seriePhoto: any) => {
    try {
        const response = await baseAPI.post(`/series/${serieId}/photos`, seriePhoto);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        console.log('addSeriePhoto ERROR', error);
        handleError(error?.response?.status, error);
    }
};

export const getSeriePhotos = async (serieId: number) => {
    try {
        const response = await baseAPI.get(`/serie-photos/${serieId}`);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        handleError(error?.response?.status, error);
    }
};

export const deleteSeriePhotos = async (photoIds: { photo_ids:number[] }) => {
    try {
        const response = await baseAPI.post(`/series/delete-photos`, photoIds);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        console.log('deleteSeriePhotos ERROR', error);
        handleError(error?.response?.status, error);
    }
};

export const deleteSerie = async (serieId: number) => {
    try {
        const response = await baseAPI.delete(`/series/${serieId}`);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        handleError(error?.response?.status, error);
    }
};

// Expo endpoints

export const addExpo = async (expo: any) => {
    try {
        const response = await baseAPI.post(`/exhibitions`, expo);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        console.log('addExpo ERROR', error);
        handleError(error?.response?.status, error);
    }
};

export const updateExpo = async (expoId: number, expo: any) => {
    try {
        const response = await baseAPI.put(`/exhibitions/${expoId}`, expo);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        handleError(error?.response?.status, error);
    }
};

export const getExpos = async () => {
    try {
        const response = await baseAPI.get(`/exhibitions`);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        handleError(error?.response?.status, error);
    }
};

export const getExpo = async (expoId: number) => {
    try {
        const response = await baseAPI.get(`/exhibitions/${expoId}`);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        handleError(error?.response?.status, error);
    }
};

export const addExpoPhoto = async (expoId: number, expoPhoto: any) => {
    try {
        const response = await baseAPI.post(`/exhibitions/${expoId}/photos`, expoPhoto);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        console.log('addExpoPhoto ERROR', error);
        handleError(error?.response?.status, error);
    }
};

export const getExpoPhotos = async (expoId: number) => {
    try {
        const response = await baseAPI.get(`/exhibitions/${expoId}/photos`);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        handleError(error?.response?.status, error);
    }
};

export const deleteExpoPhotos = async (photoIds: { photo_ids:number[] }) => {
    try {
        const response = await baseAPI.post(`/exhibitions/delete-photos`, photoIds);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        console.log('deleteExpoPhotos ERROR', error);
        handleError(error?.response?.status, error);
    }
};

export const deleteExpo = async (expoId: number) => {
    try {
        const response = await baseAPI.delete(`/exhibitions/${expoId}`);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        handleError(error?.response?.status, error);
    }
};

export const sendContactMessage = async (message: any) => {
    try {
        const response = await baseAPI.post('/send-email', message);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        console.log('sendContactMessage ERROR', error);
        handleError(error?.response?.status, error);
    }
};

export const checkAuth = async () => {
    try {
        const response = await baseAPI.post('/check-auth');
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        console.log('checkAuth ERROR', error);
        handleError(error?.response?.status, error);
    }
};

// Gallery endpoints


export const addGallery = async (gallery: any) => {
    try {
        const response = await baseAPI.post(`/galleries`, gallery);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        console.log('addGallery ERROR', error);
        handleError(error?.response?.status, error);
    }
};

export const getGalleries = async () => {
    try {
        const response = await baseAPI.get(`/galleries`);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        handleError(error?.response?.status, error);
    }
};

export const getGallery = async (galleryId: number) => {
    try {
        const response = await baseAPI.get(`/galleries/${galleryId}`);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        handleError(error?.response?.status, error);
    }
};

export const addGalleryPhoto = async (galleryId: number, galleryPhoto: any) => {
    try {
        const response = await baseAPI.post(`/galleries/${galleryId}/photos`, galleryPhoto);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        console.log('addGalleryPhoto ERROR', error);
        handleError(error?.response?.status, error);
    }
};

export const getGalleryPhotos = async (galleryId: number) => {
    try {
        const response = await baseAPI.get(`/galleries/${galleryId}/photos`);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        handleError(error?.response?.status, error);
    }
};

export const deleteGalleryPhotos = async (photoIds: { photo_ids:number[] }) => {
    try {
        const response = await baseAPI.post(`/galleries/delete-photos`, photoIds);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        console.log('deleteGalleryPhotos ERROR', error);
        handleError(error?.response?.status, error);
    }
};

export const deleteGallery = async (galleryId: number) => {
    try {
        const response = await baseAPI.delete(`/galleries/${galleryId}`);
        return handleSuccess(response?.status, response);
    } catch (error: any) {
        handleError(error?.response?.status, error);
    }
};

// export const logoutUser = async () => {
//     try {
//         const response = await baseAPI.delete(`/logout`);
//         return handleSuccess(response?.status, response);
//     } catch (error: any) {
//         handleError(error?.response?.status, error);
//     }
// };

// export const getUser = async (userId: string) => {
//     try {
//         const response = await baseAPI.get(`/${userId}`);
//         return handleSuccess(response?.status, response);
//     } catch (error: any) {
//         handleError(error?.response?.status, error);
//     }
// };

// export const updateUser = async (userId: string, updates: User) => {
//     try {
//         const response = await baseAPI.patch(`/${userId}`, updates);
//         return handleSuccess(response?.status, response);
//     } catch (error: any) {
//         handleError(error?.response?.status, error);
//     }
// };

// export const deleteUser = async (userId: string) => {
//     try {
//         const response = await baseAPI.delete(`/${userId}`);
//         return handleSuccess(response?.status, response);
//     } catch (error: any) {
//         handleError(error?.response?.status, error);
//     }
// };