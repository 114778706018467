import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {Link} from "react-router-dom";
import { useLang } from '../Layout/Layout';

interface Props {}

const Shop: React.FC<Props> = () => {
  const { lang } = useLang();
  return (
  	<Container>
  		<Row>
  			<Col md={{span: 6}} style={{textAlign: 'justify'}}>
          {
            lang === 'fr' ?
              <>
                <p>Je vous propose ici des images issues de projets personnels ou bien de moments pris sur le vif.</p>
                <p>Pour les photos d’œuvres d’art, les images ne peuvent être tirées qu’en 30 exemplaires maximum (tous formats confondus).</p>
                <p>Certaines séries sont quant à elles tirées qu’en 10 exemplaires.</p>
                <p>Chaque tirage est tiré par un laboratoire photo professionnel, il est également numéroté et signé par l’auteur (ZIROURI Basma).</p>
                <p>Vous recevrez un certificat d’authenticité avec le numéro du tirage correspondant.</p>
              </>
            : lang === 'en' ?
              <>
                <p>Here are images from personal projects or moments taken on the spot.</p>
                <p>For artwork photos, images can only be printed in a maximum of 30 copies (all formats combined).</p>
                <p>Some series are only printed in 10 copies.</p>
                <p>Each print is printed by a professional photo laboratory, it is also numbered and signed by the author (ZIROURI Basma).</p>
                <p>You will receive a certificate of authenticity with the corresponding draw number.</p>
              </>
            : null
          }
        </Col>
  		</Row>
      <Row>
        <Col md={{span: 6}} style={{textAlign: 'justify'}}>
          <Link to="https://zirouribasma.bigcartel.com">{ lang === 'fr' ? 'Accès à la boutique' : lang === 'en' ? 'Access to the shop' : null}</Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Shop;