import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

interface Props {}

const Terms: React.FC<Props> = () => {
  return (
  	<Container>
  		<Row>
  			<Col md={{span: 5}} className="mt-5" style={{textAlign: 'justify'}}>
      			<p>INFORMATIONS LEGALES : </p>
            <p style={{marginBottom: 0}}>Nom de l’entreprise : Graine de souvenirs </p>
            <p style={{marginBottom: 0}}>Raison sociale : Auto-entrepreneur</p>
            <p style={{marginBottom: 0}}>Nom de la dirigeante : Basma NASRI</p>
            <p style={{marginBottom: 0}}>Localisation : Montreuil (93100)</p>
            <p style={{marginBottom: 0}}>Mail : basma.photographe@gmail.com</p>
            <p style={{marginBottom: 0}}>SIRET : 879 338 945 00014</p>
            <p>Code APE : 7420Z (TVA non applicable)</p>
      			<p>PROPRIETE INTELLECTUELLE : </p>
            <p>Toutes les images de ce site sont régies par la loi 92-597 du 1° Juillet 1992 sur le code de la propriété intellectuelle, par conséquent les photographies ainsi que les textes sont la propriété exclusive de Graine de souvenirs. Toute personne utilisant ou reproduisant les textes et images de ce site, sans accord préalable de ma part, s’exposent à des poursuites judiciaires. Cela dit, conformément à l’article L.122-5 du Code de la propriété intellectuelle, les courtes citations du contenu sont autorisées, sous réserve que soient indiqués clairement le nom de l’auteur et de la source, par un lien vers une pages de ce site web.</p>
  			</Col>
  		</Row>
    </Container>
  );
};

export default Terms;