import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import './App.css';

// Screens
import Layout from './screens/Layout/Layout';
import Home from './screens/Home/Home';
import Galerie from './screens/Galerie/Galerie';
import GalerieSerie from './screens/Galerie/GalerieSerie';
import Serie from './screens/Serie/Serie';
import Expositions from './screens/Expositions/Expositions';
import Exposition from './screens/Expositions/Exposition';
import About from './screens/About/About';
import Shop from './screens/Shop/Shop';
import Contact from './screens/Contact/Contact';
import Terms from './screens/Terms/Terms';
import Dashboard from './screens/Dashboard/Dashboard';
import DashboardLogin from './screens/Dashboard/Login';
import DashboardHome from './screens/Dashboard/Home';
import NoMatch from './screens/NoMatch/NoMatch';

function App() {

  useEffect(() => {
    const handleContextmenu = (e:any) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  },[]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/galerie" element={<Galerie />} />
          <Route path="/galerie/:galleryId" element={<GalerieSerie />} />
          <Route path="/serie/:serieId" element={<Serie />} />
          <Route path="/expositions" element={<Expositions />} />
          <Route path="/exposition/:expoId" element={<Exposition />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<Terms />} />
        </Route>
        <Route path="/0626dashboard" element={<Dashboard />}>
          <Route index element={<DashboardLogin />} />
          <Route path="home" element={<DashboardHome />} />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
}

export default App;
