import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { getGalleries } from '../../api/api';


const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api`;

interface Props {}

const Galerie: React.FC<Props> = () => {

	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [categories, setCategories] = useState<any[]>([]);

	// Request gallery's data

	const requestGalleries = async () => {
		
		setIsLoading(true);
		
		try {
			const response = await getGalleries();

			setIsLoading(false);

			if (response.error) {
				setError(response.error);
				return;
			}

			if (response.length > 0)
				setCategories(response);
			else
				setCategories([]);

		} catch (err: any) {
			console.log('CATCH ERR requestGalleries', err, err.message);
			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while getting expos. Please try again later.');
		}
	}

	useEffect(() => {
		requestGalleries();
	},[]);
  
  return (
  	<Container>
  			<Row>
  				<h2>Galerie</h2>
  			</Row>
      		<Row>
      			<Col md={{ span: 12 }}>
      				<Row>
		      			{
		      				categories.map((categorie, categorieIndex) => <Col key={categorieIndex} md={4} style={{paddingTop: 12, paddingBottom: 12}}>
		      						<Link style={{textDecoration: 'none', color: 'black'}} to={`/galerie/${categorie.id}`}>
			      						<Row>
					      					<img src={`${BASE_URL}/photos/o/${categorie.filename}`} alt="Alt" style={{width: '100%', height: '28vh'}} />
					      				</Row>
			      						<Row>
					      					<span className="mt-1">{categorie.name}</span>
					      				</Row>
				      				</Link>
				      			</Col>
		      				)
		      			}
	      			</Row>
      			</Col>
      		</Row>
    	</Container>
  );
};

export default Galerie;