class BadRequestError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'BadRequestError';
  }
}

class UnauthorizedError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'UnauthorizedError';
  }
}

class ForbiddenError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'ForbiddenError';
  }
}

class NotFoundError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'NotFoundError';
  }
}

class InternalServerError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'InternalServerError';
  }
}

export const handleSuccess = (status: number, response: any) => {
    switch (status) {
        case 200:
            return response.data;
        case 201:
            return response.data;
        default:
            return { message: 'Request Success' };
    }
}

export const handleError = (status: number, error: any) => {
    console.log('handleError Error', status, error);
    switch (status) {
        case 400:
            throw new BadRequestError(error.response.data.error || 'Bad Request');
        case 401:
            throw new UnauthorizedError(error.response.data.error || 'Unauthorized');
        case 403:
            throw new ForbiddenError(error.response.data.error || 'Forbidden');
        case 404:
            throw new NotFoundError(error.response.data.error || 'Not Found');
        case 500:
            console.log('InternalServerError', error.response.data.error);
            throw new InternalServerError('Internal Server Error');
        default:
            throw new Error(error.message || 'Undefined Error');
    }
}