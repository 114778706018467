import axios from 'axios';
import { baseAPI } from '../api/api';

const removeKeys = async () => {
  try {
    await localStorage.clear();
    delete axios.defaults.headers.common['Authorization'];
    delete baseAPI.defaults.headers.common['Authorization'];
  } catch(e) {
    // remove error
    console.log('removeKeys ERROR', e);
    throw new Error('Internal Error');
  }
};

export default removeKeys;