import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { getExpoPhotos, getExpo } from '../../api/api';
import leftArrowIcon from '../../assets/icons/left-arrow.png';
import rightArrowIcon from '../../assets/icons/right-arrow.png';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api`;

interface Props {}

const Exposition: React.FC<Props> = () => {

	let { expoId } = useParams();
	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [expo, setExpo] = useState<any>(null);
	const [expoPhotos, setExpoPhotos] = useState<any[]>([]);
	const [selectedPicture, setSelectedPicture] = useState<{ id:number; title:string; filename:string; date:string; exhibition_id:number } | null>(expoPhotos.length > 0 ? expoPhotos[0] : null);


	const requestExpo = async (expoId:any) => {

		setIsLoading(true);

		try {
			const response = await getExpo(expoId);

			setIsLoading(false);

			if (response.error) {
				setError(response.error);
				return;
			}

			if (response.length > 0) {
				setExpo(response[0]);
			}

		} catch (err: any) {
			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while getting expo. Please try again later.');
		}
	}

	const requestExpoPhotos = async (expoId:any) => {

		setIsLoading(true);

		try {
			const response = await getExpoPhotos(expoId);

			setIsLoading(false);

			if (response.error) {
				setError(response.error);
				return;
			}

			if (response.length > 0) {
				setExpoPhotos(response);
				setSelectedPicture(response[0]);
			} else {
				setExpoPhotos([]);
			}

		} catch (err: any) {
			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while getting expo photos. Please try again later.');
		}
	}

	const nextPhoto = (picture:any) => {
		const index = expoPhotos.map((e) => {return e.id;}).indexOf(picture.id);
		setSelectedPicture(expoPhotos[index + 1]);
	}

	const prevPhoto = (picture:any) => {
		const index = expoPhotos.map((e) => {return e.id;}).indexOf(picture.id);
		setSelectedPicture(expoPhotos[index - 1]);
	}


	useEffect(() => {
		if (expoId) {
			requestExpo(expoId);
			requestExpoPhotos(expoId);
		}
	},[expoId]);

  	return (
  		<Container>
      		<Row>
      			<h2 className="p-1">{ expo && expo.name }</h2>
      		</Row>
      		<Row style={{textAlign: 'justify'}}>
   				<Col>
					<pre style={{marginBottom: 0, fontFamily: 'Open-sans-condensed-light'}}>{ expo && expo.description }</pre>
				</Col>
		    </Row>
      		<Row className="justify-content-md-center">
      			{
      				expoPhotos.map((photo, photoIndex) => <Col key={photoIndex} md={1} style={{paddingTop: 12, paddingBottom: 12}}>
		      				<img src={`${BASE_URL}/photos/m/${photo.filename}`} alt="Alt" style={{width: '100%', cursor: 'pointer'}} onClick={() => setSelectedPicture(photo)} />
		      			</Col>
      				)
      			}
      		</Row>
      		<Row>
	  			<Col md={1} style={{display : 'flex', alignItems: 'center'}}>
	  				{ selectedPicture && selectedPicture.id > expoPhotos[0].id ? <img src={leftArrowIcon} alt="Left arrow" style={{width: '100%'}} onClick={() => prevPhoto(selectedPicture)}/> : null }
	  			</Col>
      			<Col md={{span: 10}}>
	      			{ selectedPicture && selectedPicture.filename ? <img src={`${BASE_URL}/photos/o/${selectedPicture.filename}`} alt="Alt" style={{maxWidth: '100%', maxHeight: '50vh'}} /> : null }
	  			</Col>
	  			<Col md={1} style={{display : 'flex', alignItems: 'center'}}>
	  				{ selectedPicture && selectedPicture.id < expoPhotos[expoPhotos.length - 1].id ? <img src={rightArrowIcon} alt="Right arrow" style={{width: '100%'}} onClick={() => nextPhoto(selectedPicture)}/> : null }
	  			</Col>
      		</Row>
      		<Row>
      			{ selectedPicture && selectedPicture.title ? <span className="p-3">{ selectedPicture.title }</span> : null }
      		</Row>
    	</Container>
  	);
};

export default Exposition;