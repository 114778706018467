import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { authUser } from '../../api/api';

interface Props {}

const Login: React.FC<Props> = () => {
	const navigate = useNavigate();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState<string | undefined>(undefined);
	const [success, setSuccess] = useState<string | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const storeData = async (token:string) => {
		try {
			await localStorage.setItem('@access_token', token)
		} catch (e) {
			console.log('ERROR storeData', e);
			setError('An error occurred while sign in. Please try again later.');
		}
	}

	const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
		setUsername(e.target.value);
	};

	const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		// Implement your login logic here

		setIsLoading(true);
    
		if (username && password) {
			try {
				const response = await authUser({ username, password });

				setIsLoading(false);

				console.log('RESPONSE', response);
				if (response.error) {
					setError(response.error);
					return;
				}

				if (!response.access_token) {
					setError('Token error');
					return;
				} else {
					storeData(response.access_token);
				}

				if (response.message)
					setSuccess(response.message);

				// reset states
				setError('');
				setUsername('');
				setPassword('');

				// redirect to home screen
				navigate("/0626dashboard/home");

			} catch (err: any) {
				console.log('CATCH ERR', err, err.message);

				setIsLoading(false);

				if (err && err.message)
					setError(err.message);
				else
					setError('An error occurred while sign in. Please try again later.');
			}
		} else {
			setIsLoading(false);
			setError('Please fill all the fields');
		}
	};

	return (
		<Container className="mt-5">
			<Row>
				<h2 style={{ color: 'black', textAlign: 'center', marginBottom: 25 }}>Dashboard</h2>
			</Row>
			<Row>
			  <Col md={{span: 4, offset: 4}}>
				  <Form onSubmit={handleSubmit} className="mb-2">
					<Form.Group className="mb-3" controlId="formEmail">
					  <Form.Control
						type="email"
						placeholder="Enter email"
						value={username}
						onChange={handleEmailChange}
						style={{ backgroundColor: 'white', color: 'black', borderRadius: 0, borderBottom: '1px solid black' }}
						required
					  />
					</Form.Group>

					<Form.Group className="mb-3" controlId="formPassword">
					  <Form.Control
						type="password"
						placeholder="Password"
						value={password}
						onChange={handlePasswordChange}
						style={{ backgroundColor: 'white', color: 'black', borderRadius: 0, borderBottom: '1px solid black' }}
						required
					  />
					</Form.Group>

					<Button variant="dark" type="submit" style={{ width: '100%', borderRadius: 0 }}>
					  Login
					</Button>
					<span style={{color: 'red'}}>{ error }</span>
				  </Form>
			  </Col>
			</Row>
		</Container>
	);
};

export default Login;