import React from 'react';

interface Props {}

const NoMatch: React.FC<Props> = () => {
  return (
  	<div>
      NoMatch
    </div>
  );
};

export default NoMatch;