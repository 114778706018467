import React, { useState, useEffect } from 'react';
import { Outlet, useOutletContext, Link, useLocation} from "react-router-dom";
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { getSeries } from '../../api/api';
import instagramIcon from '../../assets/icons/instagram.png';

import './Layout.css';

interface Props {}

const Layout: React.FC<Props> = () => {

	const i18n:any = {
		'fr': {
			home: 'Accueil',
			gallery: 'Galerie',
			series: 'Series',
			exhibitions: 'Expositions',
			shop: 'Boutique',
			about: 'A propos',
			contact: 'Contact'
		},
		'en': {
			home: 'Home',
			gallery: 'Gallery',
			series: 'Series',
			exhibitions: 'Exhibitions',
			shop: 'Shop',
			about: 'About',
			contact: 'Contact'
		}
	};

	const [showSeries, setShowSeries] = useState<boolean>(false);

	let location = useLocation();

	const [lang, setLang] = useState<string>('fr');
	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [series, setSeries] = useState<any[]>([]);
	const [showButton, setShowButton] = useState(false);

	// Request serie's data

	const requestSeries = async () => {
		
		setIsLoading(true);
		
		try {
			const response = await getSeries();

			setIsLoading(false);

			if (response.error) {
				setError(response.error);
				return;
			}

			if (response.length > 0)
				setSeries(response);
			else
				setSeries([]);

		} catch (err: any) {
			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while getting series. Please try again later.');
		}
	}

	useEffect(() => {
		window.addEventListener("scroll", () => {
			if (window.pageYOffset > 300) {
				setShowButton(true);
			} else {
				setShowButton(false);
			}
		});
	}, []);

	// This function will scroll the window to the top 
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth' // for smoothly scrolling
		});
	};

	useEffect(() => {
		requestSeries();
	},[]);

  	return (
  	<>
	  	<Container fluid style={{paddingLeft: 48, paddingRight: 48}}>
		  	<nav className="pt-3">
		  		<Row>
		  			<Col md={10}>
						<h3 style={{color: 'black', fontSize: 41, textAlign: 'start'}}>
							<Link style={{textDecoration: 'none', color: 'black'}} to="">Basma ZIROURI</Link>
						</h3>
		  			</Col>
		  			<Col md={2}>
						<select name="language" id="language" style={{backgroundColor: 'transparent', border: 0}} value={lang} onChange={(e) => setLang(e.target.value)}>
						  	<option value="fr" style={{backgroundColor: 'transparent', border: 0}}>FR</option>
						  	<option value="en" style={{backgroundColor: 'transparent', border: 0}}>EN</option>
						</select>
		  			</Col>
				</Row>
			</nav>
			<Row style={{paddingBottom: 35}}>
				<Col md={2}>
					<ListGroup>
						<ListGroup.Item style={{border: 0, backgroundColor: 'transparent', textAlign: 'left'}}>
							<Link style={{textDecoration: 'none', color: 'black'}} to="">{i18n[lang].home}</Link>
						</ListGroup.Item>
						<ListGroup.Item style={{border: 0, backgroundColor: 'transparent', textAlign: 'left'}}>
							<Link style={{textDecoration: 'none', color: 'black'}} to="galerie">{i18n[lang].gallery}</Link>
						</ListGroup.Item>
						<ListGroup.Item style={{border: 0, backgroundColor: 'transparent', textAlign: 'left'}}>
							<a style={{textDecoration: 'none', color: 'black', cursor: 'pointer'}} onClick={() => setShowSeries(!showSeries)}>{i18n[lang].series}</a>
							{
								showSeries ?
									<ListGroup>
										{
											series.map((serie, serieIndex) =>
												<ListGroup.Item key={serieIndex} style={{border: 0, backgroundColor: 'transparent', textAlign: 'left'}}>
													<Link style={{textDecoration: 'none', color: 'black'}} to={`serie/${serie.id}`}>{serie.name}</Link>
												</ListGroup.Item>
											)
										}
									</ListGroup>
								: null
							}
						</ListGroup.Item>
						<ListGroup.Item style={{border: 0, backgroundColor: 'transparent', textAlign: 'left'}}>
							<Link style={{textDecoration: 'none', color: 'black'}} to="expositions">{i18n[lang].exhibitions}</Link>
						</ListGroup.Item>
						<ListGroup.Item style={{border: 0, backgroundColor: 'transparent', textAlign: 'left'}}>
							<Link style={{textDecoration: 'none', color: 'black'}} to="shop">{i18n[lang].shop}</Link>
						</ListGroup.Item>
						<ListGroup.Item style={{border: 0, backgroundColor: 'transparent', textAlign: 'left'}}>
							<Link style={{textDecoration: 'none', color: 'black'}} to="about">{i18n[lang].about}</Link>
						</ListGroup.Item>
						<ListGroup.Item style={{border: 0, backgroundColor: 'transparent', textAlign: 'left'}}>
							<Link style={{textDecoration: 'none', color: 'black'}} to="contact">{i18n[lang].contact}</Link>
						</ListGroup.Item>
				    </ListGroup>
				</Col>
				<Col md={8}>
					<Outlet context={{ lang }}/>
				</Col>
				<Col md={2}>
				</Col>
			</Row>
			<Row style={{position: 'fixed', bottom: 0, width: '100%', paddingTop: 15, paddingBottom: 15}}>
				<Col md={5}>
				</Col>
				<Col md={2}>
					<span style={{textDecoration: 'none', color: 'black'}}>© Basma Zirouri - <Link to="terms">Terms and conditions</Link></span>
				</Col>
				<Col md={3}>
					<Link to="https://www.instagram.com/basma.photographe"><img src={instagramIcon} alt="Instagram" style={{width: 23, height: 23}}/></Link>
				</Col>
				<Col>
					{showButton && (
						<button onClick={scrollToTop} style={{ position: 'fixed', bottom: 20, right: 20, fontSize: 27, background: 'black', color: 'white', cursor: 'pointer', border: 'none', boxShadow: '0 5px 10px #ccc' }}>
							&#8638;
						</button>
					)}
				</Col>
			</Row>
		</Container>
	</>
  );
};

export default Layout;

export function useLang() {
	return useOutletContext<{lang:string}>();
}