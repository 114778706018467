import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { getSeriePhotos, getSerie } from '../../api/api';
import leftArrowIcon from '../../assets/icons/left-arrow.png';
import rightArrowIcon from '../../assets/icons/right-arrow.png';
import { useLang } from '../Layout/Layout';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api`;

interface Props {}

const Serie: React.FC<Props> = () => {

	let { serieId } = useParams();
  	const { lang } = useLang();
	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [serie, setSerie] = useState<any>(null);
	const [seriePhotos, setSeriePhotos] = useState<any[]>([]);
	const [selectedPicture, setSelectedPicture] = useState<{ id:number; title:string; filename:string; date:string; photo_serie_id:number } | null>(seriePhotos.length > 0 ? seriePhotos[0] : null);

	const requestSerie = async (serieId:any) => {

		setIsLoading(true);

		try {
			const response = await getSerie(serieId);

			setIsLoading(false);

			if (response.error) {
				setError(response.error);
				return;
			}

			if (response.length > 0) {
				setSerie(response[0]);
			}

		} catch (err: any) {
			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while getting serie. Please try again later.');
		}
	}

	const requestSeriePhotos = async (serieId:any) => {

		setIsLoading(true);

		try {
			const response = await getSeriePhotos(serieId);

			setIsLoading(false);

			if (response.error) {
				setError(response.error);
				return;
			}

			if (response.length > 0) {
				setSeriePhotos(response);
				setSelectedPicture(response[0]);
			} else {
				setSeriePhotos([]);
			}

		} catch (err: any) {
			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while getting serie photos. Please try again later.');
		}
	}

	const nextPhoto = (picture:any) => {
		const index = seriePhotos.map((e) => {return e.id;}).indexOf(picture.id);
		setSelectedPicture(seriePhotos[index + 1]);
	}

	const prevPhoto = (picture:any) => {
		const index = seriePhotos.map((e) => {return e.id;}).indexOf(picture.id);
		setSelectedPicture(seriePhotos[index - 1]);
	}


	useEffect(() => {
		if (serieId) {
			requestSerie(serieId);
			requestSeriePhotos(serieId);
		}
	},[serieId]);

  	return (
  		<Container>
      		<Row>
      			<h2 className="p-1">{ serie && serie.name }</h2>
      		</Row>
      		{
      			serie?.name === "Les mains bleues" ?
      				<Row style={{textAlign: 'justify'}}>
      					<Col md={{span: 10, offset: 1}}>
      						{
      							lang === 'fr' ?
		      						<>
										<p style={{marginBottom: 0}}>Cette série photographique : Les mains bleues, est un hommage à 10 femmes
										qui ont marqué l’histoire.</p>
										<p style={{marginBottom: 0}}>Il me semblait évident d’utiliser des couleurs vives car chacune de ses femmes
										a été une lumière pour l’histoire.</p>
										<p style={{marginBottom: 0}}>Je les ai associés aux fleurs car la femme possède en elle la fleur qui met au
										monde l’humanité.</p>
										<p style={{marginBottom: 0}}>Des femmes qu’on a voulu faire taire mais qui n’ont rien lâcher, elles ont parlé,
										crié, chanter, sculpter...certaines se sont assises pour rester debout.</p>
										<p style={{marginBottom: 0}}>Des femmes qui se battent et se débattent depuis la nuit des temps contre les
										diktats.</p>
									</>
      							: lang === 'en' ?
		      						<>
										<p style={{marginBottom: 0}}>This photographic series, Blue Hands, is a tribute to 10 women who have left their mark on history.</p>
										<p style={{marginBottom: 0}}>It seemed obvious to me to use bright colors because each of these women has been a light to history.</p>
										<p style={{marginBottom: 0}}>I have associated them with flowers because woman possesses within herself the flower that gives birth to humanity.</p>
										<p style={{marginBottom: 0}}>Women who we wanted to silence but who didn't give up, they talked, shouted, sang, sculpted... Some sat down to stand.</p>
										<p style={{marginBottom: 0}}>Women who have been fighting and struggling since the dawn of time against diktats.</p>
									</>
      							: null
      						}
						</Col>
		      		</Row>
		      	: null
      		}
      		<Row className="justify-content-md-center">
      			{
      				seriePhotos.map((photo, photoIndex) => <Col key={photoIndex} md={1} style={{paddingTop: 12, paddingBottom: 12}}>
		      				<img src={`${BASE_URL}/photos/m/${photo.filename}`} alt="Alt" style={{width: '100%', cursor: 'pointer'}} onClick={() => setSelectedPicture(photo)} />
		      			</Col>
      				)
      			}
      		</Row>
      		<Row>
	  			<Col md={1} style={{display : 'flex', alignItems: 'center'}}>
	  				{ selectedPicture && selectedPicture.id > seriePhotos[0].id ? <img src={leftArrowIcon} alt="Left arrow" style={{width: '100%'}} onClick={() => prevPhoto(selectedPicture)}/> : null }
	  			</Col>
      			<Col md={{span: 10}}>
	      			{ selectedPicture && selectedPicture.filename ? <img src={`${BASE_URL}/photos/o/${selectedPicture.filename}`} alt="Alt" style={{maxWidth: '100%', maxHeight: '60vh'}} /> : null }
	  			</Col>
	  			<Col md={{span: 1}} style={{display : 'flex', alignItems: 'center'}}>
	  				{ selectedPicture && selectedPicture.id < seriePhotos[seriePhotos.length - 1].id ? <img src={rightArrowIcon} alt="Right arrow" style={{width: '100%'}} onClick={() => nextPhoto(selectedPicture)}/> : null }
	  			</Col>
      		</Row>
      		<Row>
      			{ selectedPicture && selectedPicture.title ? <span className="p-3">{ selectedPicture.title }</span> : null }
      		</Row>
    	</Container>
  	);
};

export default Serie;